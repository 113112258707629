<template>
  <div class="live-content">
<!--    <img src='../assets/live-banner.png' alt='' class='banner-img'>-->
    <div class='banner_1'>
      <div class='swiper_content'>
        <!--        <div class="swiper-container">-->
<!--        <swiper :options='swiperOptions1'>-->
<!--          <swiper-slide v-for='(item,index) in logoList' :key='index'>-->
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url&&logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
        </div>
<!--          </swiper-slide>-->
          <!-- 如果需要分页器 slot插槽 会自动往里面渲染分页器-->
          <!--                      <div class="swiper-pagination" slot="pagination"></div>-->
          <!-- 如果需要导航按钮 -->
          <!--          <div class='swiper-button-prev prev1' slot='button-prev'></div>-->
          <!--          <div class='swiper-button-next next1' slot='button-next'></div>-->
<!--        </swiper>-->
        <!--      </div>-->
      </div>
    </div>
<!--    头部主播-->
    <div class='head-info-item'>
<!--      <div class='rectangle-position'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
      <div class='header-anchor'>
       <div style='margin: 30px 0 30px 0;text-align: center;'>
         <span>{{ $t('LIVE.headAnchor') }}</span>
<!--         <img src='../assets/head-anchor.png' alt='' class="images">-->
       </div>
       <div style='position: relative'>
         <div class='anchor-content-info'>
           <div class="swiper swiper1">
             <div class="swiper-wrapper">
               <div class="swiper-slide" v-for='(item,index) in head_anchor' :key='index'>
                 <!--               <div class="anchor-list">-->
                 <!--                 <div>{{ item.title }}</div>-->
                 <!--                 <div>初夏蓝天杯-季军 十大影响力主播</div>-->
                 <!--                 <div>抖音号:tiancidaqiao</div>-->
                 <!--                 <span @click='join_hx'>加入华星 <i class='el-icon-d-arrow-right'></i></span>-->
                 <!--               </div>-->
                 <a :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'>
                   <img :src='item.img' alt='' class="anchor-avatar">
                 </a>
<!--                 <div class='know_us'>-->
<!--                   <a :href='item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank' style='width: 100%'><el-button class='know_more'>点击查看>>></el-button></a>-->
<!--                 </div>-->
               </div>
             </div>
             <!-- 如果需要分页器 -->
             <div class="swiper-pagination"></div>

             <!-- 如果需要导航按钮 -->
             <div class="swiper-button-prev swiper-button-top1"></div>
             <div class="swiper-button-next swiper-button-bottom1"></div>
           </div>
         </div>
       </div>
      </div>
<!--      <div class='rectangle-right'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
    </div>
    <!--    热门主播-->
    <div class='head-info-item'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('LIVE.hotAnchor') }}</span>
<!--          <img src='../assets/popular-anchor.png' alt='' class='images popular-img'>-->
        </div>
        <div style='position: relative;'>
          <div class='hot-anchor'>
            <div class="swiper swiper2">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for='(item, index) in popular_anchor' :key='index'>
                  <div style='position: relative'>
                    <!--                  <div class='nickname-s'>-->
                    <!--                    <div>晚安安</div>-->
                    <!--                    <span>抖音ID:wanan2222222</span>-->
                    <!--                  </div>-->
                    <a target='_blank' :href='item.link_url && item.link_url.indexOf("http") >= 0  ? item.link_url : null'><img v-if='item.img' :src='item.img' alt='' class="avatar2"></a>
                  </div>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button-left"></div>
              <div class="swiper-button-next swiper-button-right"></div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class='rectangle-right rights'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
    </div>
<!--    娱乐星知道-->
    <div class='head-info-item'>
<!--      <div class='left-s'>-->
<!--        <img src='../assets/left-img.png' alt=''>-->
<!--      </div>-->
<!--      <div class='rectangle-position lefts'>-->
<!--        <img src='../assets/rectangle.png' alt=''>-->
<!--      </div>-->
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('LIVE.entertainmentStar') }}</span>
<!--          <img src='../assets/entertainment.png' alt='' class='images popular-img'>-->
        </div>
        <div style='position: relative'>
          <div class='entertainment'>
            <div class="swiper swiper3">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for='(item, index) in entertainment_list' :key='index'>
                  <a style='  width: 100%;height:434px;' :href='item.link_url&&item.link_url.indexOf("http") >= 0  ? item.link_url : null' target='_blank'><img :src='item.img' alt='' class="anchor-avatar2"></a>
                </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="swiper-pagination"></div>

              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button-top"></div>
              <div class="swiper-button-next swiper-button-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='recruit-item'>
      <swiper :options='swiperOptions2'>
        <swiper-slide v-for='(item,index) in live_join_img' :key='index'>
          <img :src='item' alt=''>
        </swiper-slide>
      </swiper>
      <div class="content-s">
        <div class='form-item-s'>
          <div class="name-d">{{$t("LIVE.anchorRecruitment")}}</div>
          <el-form ref="form" :model="form" :rules="rules" label-position="left">
            <div class="back-s">
              <el-form-item label="" prop='live_id'>
                <el-input v-model="form.live_id" size='mini' :placeholder='$t("LIVE.TiktokID")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='sex'>
                <el-input v-model="form.sex" size='mini' :placeholder='$t("LIVE.sex")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='age'>
                <el-input v-model="form.age" size='mini' :placeholder='$t("LIVE.age")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='style'>
                <el-input v-model="form.style" size='mini' :placeholder='$t("LIVE.style")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='wechat'>
                <el-input v-model="form.wechat" size='mini' :placeholder='$t("LIVE.wechat")'></el-input>
              </el-form-item>
            </div>
            <div class="back-s">
              <el-form-item label="" prop='city'>
                <el-input v-model="form.city" size='mini' :placeholder='$t("LIVE.city")'></el-input>
              </el-form-item>
            </div>
            <el-form-item label-width='0' style='text-align: center'>
              <el-button type="primary" size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='data-see'>
<!--          <img src='../assets/live-recruit.png' alt='' class="image-platform">-->
<!--          <div class='title-name'>在我的平台 看你的未来</div>-->
<!--          <div class='platform-contents'>-->
<!--            <div>头部公会</div>-->
<!--            <div>专业顶尖团队</div>-->
<!--            <div>整合孵化</div>-->
<!--            <div>爆款内容打造</div>-->
<!--            <div>专业培训</div>-->
<!--            <div>艺人主播孵化</div>-->
<!--            <div>资源推广</div>-->
<!--            <div>优质资源扶持</div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import homeBanner from '../components/homeBanner'
import Swiper from 'swiper';
  import { Logo, index_List } from '@/api/banner'
import { getLiveBanner, liveJoin } from '@/api/live'
export default {
  name: 'live',
  components:{
    // homeBanner
  },
  directives: {},
  data() {
    return {
      logoList:[],
      swiperOptions1: {
        // 自动播放
        autoplay: {
          delay: 3000,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiperOptions2: {
        // 自动播放
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 环路播放
        loop: true,
        // 切换效果
        effect: 'slide',
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6
        },
        // 前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next.next1',
          prevEl: '.swiper-button-prev.prev1',
        }
      },
      swiper: {
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
          // dynamicBullets: true,
          // dynamicMainBullets: 3,
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper2:  {
        // loop: true, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        freeMode: true,
        // width: 1036,
        slidesPerColumnFill:'column',
        slidesPerColumn: 2,
        slidesPerView: 3.51,
        slidesPerGroup: 4,
        // virtualTranslate : true,
        on: {
          slideChange: function(){           //当swiper切换时,下面是项目里需要用到的js
            let nowTabIndex = this.activeIndex;
            let num = (this.slides.length / 7) * 3
            if(this.activeIndex % 3 != 0 || num <= this.activeIndex) {
              // this.setTranslate(0)
              // this.activeIndex = 0
            }
            // console.log(nowTabIndex,num, this.slides.length)
          },
          progress: function (progress) {
            // console.log(progress)
          }
        },
        speed:2000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      swiper3: {
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          bulletClass : 'my-bullet',//需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active',
          clickable :true,
        },
        // height: 434,//你的slide高度 强制性 垂直方向生效
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 1,
            coverflowEffect: {
              slideShadows: false, // 页面阴影效果
              rotate: 0,// 旋转的角度
              stretch: 0,// 拉伸 图片间左右的间距和密集度
              depth: 0,// 深度 切换图片间上下的间距和密集度
              modifier: 0,// 修正值 该值越大前面的效果越明显
            },
          },
          1000: {  //当屏幕宽度大于等于1000
            slidesPerView: 1,
            coverflowEffect: {
              slideShadows: false, // 页面阴影效果
              rotate: 0,// 旋转的角度
              stretch: 0,// 拉伸 图片间左右的间距和密集度
              depth: 0,// 深度 切换图片间上下的间距和密集度
              modifier: 0,// 修正值 该值越大前面的效果越明显
            },
          },
          1200: {  //当屏幕宽度大于等于1000
            height: 434,//你的slide高度 强制性 垂直方向生效
            coverflowEffect: {
              slideShadows: false, // 页面阴影效果
              rotate: 0,// 旋转的角度
              stretch: 500,// 拉伸 图片间左右的间距和密集度
              depth: 0,// 深度 切换图片间上下的间距和密集度
              modifier: .8,// 修正值 该值越大前面的效果越明显
            },
          }
        },
        effect: 'coverflow', //进出动画
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        //longSwipesRatio: 0.2, //滑动多少就可以滑动
        on: {
          progress: function (progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              slide.css('opacity', 1);
              if (slideProgress == -1) {
                slide.css('opacity', 0.5);
              }
              if (slideProgress == -2) {
                slide.css('opacity', 0.3);
              }
              if (slideProgress <= -3 || slideProgress >= 1) {
                slide.css('opacity', 0);
              }
            }
          },
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        initialSlide :0,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true//修改swiper的父元素时，自动初始化swiper
      },
      form: {
        live_id: '', // 抖音id
        age: '', // 年龄
        sex: '', // 性别
        style: '', //风格类型
        wechat: '', // 微信号
        city: '', // 所在城市
      },
      // rules: {
      //   live_id: [
      //     { required: true, message: '请输入抖音ID(视频号)', trigger: 'blur' }
      //   ],
      //   age: [
      //     { required: true, message: '请输入年龄', trigger: 'blur' }
      //   ],
      //   sex: [
      //     { required: true, message: '请输入性别', trigger: 'blur' }
      //   ],
      //   style: [
      //     { required: true, message: '请输入风格类型', trigger: 'blur' }
      //   ],
      //   wechat: [
      //     { required: true, message: '请输入微信号', trigger: 'blur' }
      //   ],
      //   city: [
      //     { required: true, message: '请输入所在城市', trigger: 'blur' }
      //   ],
      // },
      head_anchor: [], // 头部主播
      popular_anchor: [], // 热门主播
      entertainment_list: [], // 娱乐星知道
      live_join_img: '', // 主播招募背景图
    }
  },
  computed: {
    rules:function(){
      let data= {
        live_id: [
          { required: true, message: this.$t('LIVE.rules_TiktokID'), trigger: 'blur' }
        ],
          age: [
          { required: true, message: this.$t('LIVE.rules_age'), trigger: 'blur' }
        ],
          sex: [
          { required: true, message: this.$t('LIVE.rules_sex'), trigger: 'blur' }
        ],
          style: [
          { required: true, message: this.$t('LIVE.rules_style'), trigger: 'blur' }
        ],
          wechat: [
          { required: true, message: this.$t('LIVE.rules_wechat'), trigger: 'blur' }
        ],
          city: [
          { required: true, message: this.$t('LIVE.rules_city'), trigger: 'blur' }
        ],
      }
        return data;
    },
  },
  created() {

  },
  mounted() {
    this.get_Logo()
    this.get_index_List()  // 主播招募背景图
    this.get_liveBanner(1) // 头部主播
    this.get_liveBanner(2) // 热门主播
    this.get_liveBanner(3) // 娱乐星知道
    let  language=localStorage.getItem('locale')
  },
  methods:{
    get_Logo: function() {
      Logo(2).then(resp => {
        if (resp.status == 200) {
          // console.log('45454545',resp.data)
          this.$set(this, 'logoList', resp.data)
        }
      }).catch(error => {

      })
    },
    // 主播招募背景图
    get_index_List: function() {
      index_List().then(resp => {
        // console.log('resp.data.live_join_img',resp.data)
        if (resp.status == 200) {
          // let arr=[]
          // arr.push(resp.data.live_join_img)
          this.$set(this, 'live_join_img', resp.data.live_join_img)
        }
      }).catch(error => {
      })
    },
    // 头部主播、热门主播、
    get_liveBanner: function(data) {
      getLiveBanner({ type: data }).then(resp => {
        if(resp.status == 200) {
          if(data == 1) {
            this.$set(this, 'head_anchor', resp.data)
            this.$nextTick(() => {
              var mySwiper = new Swiper('.swiper1', this.swiper)
            })
          }
          if(data == 2) {
            let arr = resp.data
            if(resp.data && resp.data.length % 7 !== 0) {
              let num = Math.ceil(resp.data.length / 7) * 7
              let data = Math.abs(num - resp.data.length)
              for(let i = 0; i < data; i++) {
                arr.push({})
              }
            }
            this.$set(this, 'popular_anchor', arr)
            this.$nextTick(() => {
              var mySwiper2 = new Swiper ('.swiper2', this.swiper2)
            })
          }
          if(data == 3) {
            this.$set(this, 'entertainment_list', resp.data)
            this.$nextTick(() => {
              var mySwiper3 = new Swiper('.swiper3', this.swiper3)
            })
          }
        }else {
          this.$message(resp.data.msg)
        }
      }).catch(err => {
      })
    },
    // 提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          liveJoin(this.form).then(resp => {
            if(resp.status == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.form = {
                live_id: '', // 抖音id
                age: '', // 年龄
                sex: '', // 性别
                style: '', //风格类型
                wechat: '', // 微信号
                city: '', // 所在城市
              }
            } else {
              this.$message(resp.data.msg)
            }
          }).catch(err => {
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang='scss' scoped>
.head-info-item{
  //display: flex;
  position: relative;
  padding: 30px 0;
  .header-anchor{
    width: 60%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;

    line-height: 30px;
    .images{
      width: 261px;
      position: absolute;
      left: 50%;
    }
    .anchor-content-info{
      //background: rgba(213, 71, 36, 0.1);
      box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 40px;
      height: 428px;
      width: 90%;
      overflow: hidden;
      margin: auto;
      .el-button {
        border: none;
        outline: none;
        border-radius: 50px;
      }
      img{
        cursor: pointer;
      }
    }
    .hot-anchor{
      width: 90%;
      margin: auto;
      height: 542px;
      overflow: hidden;
      .swiper-slide{
        width: 198px !important;
        height: 261px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .swiper-wrapper {
        width: 1036px !important;
      }
      .avatar2{
        width: 198px;
        height: 261px;
        //background: rgba(213, 71, 36, 0.1);
        border-radius: 10px;
        cursor: pointer;
      }
      .swiper-slide:nth-child(7n + 1){
        width: 402px !important;
        height: 531px !important;
        //transform: scale(1.8335);
        img{
          width: 402px;
          height: 531px;
        }
      }
      .nickname-s{
        position: absolute;
        bottom: 10px;
        left: 20px;
        color: #fff;
        line-height: 25px;
        font-size: 18px;
        font-family: SourceHanSansSC;
        font-weight: 500;
        span{
          font-size: 14px;
        }
      }
    }
    .entertainment{
      width: 957px;
      margin: auto;
      height: 537px;
      overflow: hidden;
      margin-bottom: 60px;
      .swiper-slide{
        height: auto;
        width: 837px;
      }
    }
    .popular-img{
      width: 341px;
    }
    .address-word{
      width: 181px;
    }
  }
  .rectangle-position{
    position: absolute;
    bottom: 0;
    left: 60px;
    img{
      width: 104px;
      height: 73px;
    }
  }
  .lefts{
    left: 260px;
    bottom: 90px;
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    right: 100px;
    img{
      width: 106px;
      height: 73px;
    }
  }
  .rights{
    right: 160px;
  }
  .left-s{
    position: absolute;
    left: 0;

    img{
      width: 275px;
      height: 547px;
    }
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 100%;
    height: 100%;
    border-radius: 40px;
    //cursor: pointer;
  }
  .anchor-avatar2{
    width: 837px;
    height: 434px;
    border-radius: 10px;
  }
}
.banner_1 {
  width: 100%;
  height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

    .swiper-container {
      width: 100%;
      height: 700px;

      .swiper-wrapper {
        width: 100%;
        height: 700px;

        .swiper-slide {
          width: 100%;
          height: 700px;

          img {
            width: 100%;
            height: 700px;
          }
        }
      }
    }
    img {
      width: 100%;
      height: 700px;
    }
  }
}
.know_us {
  text-align: left;
  position:absolute;
  z-index: 999;
  bottom: 35px;
  left: 80px;
  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }
  .know_more:hover{
    background-color: rgba(213,71,26,0.5);

  }
  .know_more_nodata{
    background-color: rgba(213,71, 36, 0.5);
    color: #fff;
    //cursor:not-allowed;
  }
}
.know_us:hover{
  transform: scale(1.1);
}
.swiper1 {
  width: 100%;
  height: 428px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.swiper3 {
  width: 88%;
  height: 537px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
  margin:auto;
}
.swiper2 {
  width: 100%;
  height: 542px;
  --swiper-theme-color: rgba(232, 75, 36, 1);
  --swiper-navigation-size: 40px;/* 设置按钮大小 */
}
.recruit-item{
  height: 700px;
  width: 100%;
  position: relative;
  img{
    height: 700px;
    width: 100%;
  }
  .content-s{
    //width: 55%;
    margin: auto;
    //padding-top: 120px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    position: absolute;
    top:120px;
    z-index: 2;
    left: 22%;
  }
  .form-item-s{
    width: 283px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 450px;
    padding: 15px;
    margin-left: 90px;
    margin-right: 100px;
    .back-s{
      //background: #F19F8A;
      //border-radius: 25px;
      //padding: 0 10px 0 10px;
    }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
}
.data-see{
  height: 246px;
  width: 536px;
  background: url("../assets/live-recruit.png") no-repeat;
  background-size:100% 246px;
  margin-top: 79px;
  text-align: center;
  //padding: 25px 0 0 0;
  .image-platform{
    width: 90%;
    height: 26px;
  }
  .title-name{
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #D54724;
    line-height: 50px;
  }
  .platform-contents{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    padding: 15px 45px 0 45px;
    div{
      width: 50%;
    }
  }
}
.map-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: rgba(213, 71, 36, 0.1);
}
</style>
